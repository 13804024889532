import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import Small16by10 from "../Images/Small16by10";

const EventListItem = ({content}) => (
    <div className="event-list-item card relative flex flex-col h-full">
        <div className="card-image relative flex-initial">
            <Link to={content.path.alias}>
                {content.relationships.image ? (
                    <GatsbyImage
                        image={content.relationships.image.localFile.childImageSharp.gatsbyImageData}
                        alt={content.title} />
                ) : (
                    <Small16by10 alt={content.title}/>
                )}
            </Link>
        </div>
        <div className="card-content relative flex-1 py-6 px-2 border-b-20 border-primary-light border-opacity-20">
            <div className="title h3 relative mb-4 pb-4 before-underline before-bg-primary-light">
                <Link to={content.path.alias} className="text-primary-light">{content.title}</Link>
            </div>

            <div className="text-primary-light">
                <div className="date">
                    <i className="fas fa-calendar-alt mr-1"><span className="hidden">Date</span></i>
                    {(content.dates.end && (content.dates.end > content.dates.start)) ? (
                        <>
                            <span className="start-date mr-1">
                               <span className="label">Du&nbsp;</span>{content.dates.startDay}&nbsp;
                               <span className="lowercase">{content.dates.startMonth}</span>
                            </span>
                            <span className="end-date">
                                <span className="label">au&nbsp;</span>{content.dates.endDay}&nbsp;
                                <span className="lowercase">{content.dates.endMonth}&nbsp;{content.dates.endYear}</span>
                            </span>
                        </>
                    ) : (
                        <>
                            <span className="label">Le&nbsp;</span>{content.dates.startDay}&nbsp;
                            <span className="lowercase">{content.dates.startMonth}&nbsp;{content.dates.startYear}</span>
                        </>
                    )}
                </div>
                {content.emplacement && (
                    <address className="emplacement not-italic">
                        <i className="fas fa-map-marker-alt">
                            <span className="hidden">Adresse</span>
                        </i>
                        &nbsp;{content.emplacement}
                    </address>
                )}
            </div>

            <div className="link absolute bottom-0 right-6 -mb-2 transform translate-y-1/2">
                <Link to={content.path.alias} className="flex items-center justify-center h-12 w-12 bg-primary-light border-2 border-primary-light rounded-full text-white text-2xl">
                    <i className="fas fa-plus"><span className="hidden">En savoir plus</span></i>
                </Link>
            </div>
        </div>
    </div>
)

EventListItem.propTypes = {
    content: PropTypes.object.isRequired,
}

export default EventListItem
