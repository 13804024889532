import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {graphql} from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import Breadcrumb from "../components/Breadcrumb";
import OtherEvents from "../components/Blocks/OtherEvents";
import HtmlParser from "../components/HtmlParser";

const Event = ({pageContext, data}) => {
    let width = 0;
    let height = 0;
    let maxWidth = 470;
    let maxHeight = 470;

    if (data.node?.image) {
        width = data.node.image.width
        height = data.node.image.height
        let calcHeight = height * maxWidth / width

        if (calcHeight > maxHeight) {
            maxWidth = maxHeight * maxWidth / calcHeight
        }
    }

    return (
        <Layout contentType={data.node.internal.type}>
            <Metas title={data.node.title}/>
            <section className="section page-content">
                <div className="container lg:max-w-screen-lg mx-auto px-4">
                    <Breadcrumb/>

                    <h1 className="title h1 text-primary" data-typesense-field="title">{data.node.title}</h1>

                    <div className="block infos -mt-6 mb-10">
                        <div className="flex items-center -mx-2">
                            <div className="flex-initial px-2">
                                <div className="inline-block text-primary-light font-important font-semibold">
                                    <i className="fas fa-calendar-alt mr-1"><span className="hidden">Date</span></i>
                                    {(data.node.dates.end && (data.node.dates.end > data.node.dates.start)) ? (
                                        <>
                                           <span className="start-date mr-2">
                                               <span className="label">Du&nbsp;</span>{data.node.dates.startDay}&nbsp;
                                               <span className="lowercase">{data.node.dates.startMonth}.</span>
                                           </span>
                                            <span className="end-date">
                                               <span className="label">au&nbsp;</span>{data.node.dates.endDay}&nbsp;
                                                <span className="lowercase">{data.node.dates.endMonth}.</span>
                                           </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="label">Le&nbsp;</span>{data.node.dates.startDay}&nbsp;<span
                                            className="lowercase">{data.node.dates.startMonth}.</span>
                                        </>
                                    )}
                                </div>
                            </div>
                            {data.node.emplacement && (
                                <div className="flex-initial px-2">
                                    <address
                                        className="inline-block text-primary-light font-important font-semibold not-italic">
                                        <i className="fas fa-map-marker-alt"><span
                                            className="hidden">Adresse</span></i>&nbsp;{data.node.emplacement}
                                    </address>
                                </div>
                            )}
                        </div>
                    </div>

                    {(data.node?.image && data.node.relationships.image) && (
                        <>
                            {(width < maxWidth || (width/height) <= 1) ? (
                                <div className="block figure relative mb-8 overflow-hidden bg-gray-100">
                                    <GatsbyImage
                                        image={data.node.relationships.imageBackground.localFile.childImageSharp.gatsbyImageData}
                                        alt={data.node.title}
                                        className="hidden md:block opacity-80 filter blur transform scale-105" />

                                    <figure className="static md:absolute z-10 inset-x-0 top-1/2 md:transform md:-translate-y-1/2 m-auto" style={{maxWidth: `${maxWidth}px`}}>
                                        <GatsbyImage
                                            image={data.node.relationships.image.localFile.childImageSharp.gatsbyImageData}
                                            alt={data.node.title} />
                                    </figure>
                                </div>
                            ) : (
                                <div className="block figure relative mb-8">
                                    <GatsbyImage
                                        image={data.node.relationships.imageBackground.localFile.childImageSharp.gatsbyImageData}
                                        alt={data.node.title} />
                                </div>
                            )}
                        </>
                    )}

                    {data.node.body && (
                        <div className="block content mb-8" data-typesense-field="body">
                          <HtmlParser html={data.node.body.value}/>
                        </div>
                    )}
                </div>
            </section>
            
            <OtherEvents current={pageContext.internalId}/>
        </Layout>
    );
}

export default Event

export const query = graphql`query ($internalId: Int!) {
  node: nodeEvenement(status: {eq: true}, drupal_internal__nid: {eq: $internalId}) {
    title
    body {
      value
    }
    image: field_image {
      alt
      width
      height
    }
    dates: field_date {
      start: value(formatString: "X", locale: "fr")
      startDay: value(formatString: "DD", locale: "fr")
      startMonth: value(formatString: "MMM", locale: "fr")
      end: end_value(formatString: "X", locale: "fr")
      endDay: end_value(formatString: "DD", locale: "fr")
      endMonth: end_value(formatString: "MMM", locale: "fr")
    }
    emplacement: field_emplacement
    internal {
      type
    }
    relationships {
      image: field_image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 470, 
              layout: CONSTRAINED
              transformOptions: {fit: CONTAIN}
            )
          }
        }
      }
      imageBackground: field_image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 992
              height: 500
              transformOptions: {cropFocus: CENTER}
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }
}`
